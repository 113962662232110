import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import LeadIn from "../components/Content/LeadIn/Index";
import ContactForm from "../components/Content/Forms/Index";

// assets
import brandConex from "../assets/logos/logo.svg";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Contact Us"
        description="Contact us through the form below with any enquiry and we’ll be in touch within 48 hours."
      />
      <BannerBasic title="Contact" />
      <LeadIn
        brandMediaTitle="Logo"
        brandMediaUrl={brandConex}
        brandMediaWidth="306px"
        summary="Contact us through the form below with any enquiry and we’ll be in touch within 48 hours."
      >
        <ContactForm />
      </LeadIn>
    </Layout>
  );
};

export default IndexPage;
